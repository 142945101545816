import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { gutter } from '../../data/SiteConfig'
import styles from './PostsListing.module.scss'
import Title from '../elements/Title'

import { Row, Col, Card, Tag, Typography } from 'antd';

const { Text } = Typography
const { Meta } = Card;

const MetaTitle = ({date, tags}) => {
  return (
    <Fragment>
      <Tag color="volcano">{date}</Tag>
      {tags && tags.map(tag => <Tag key={tag}>{`#${tag}`}</Tag>)}
    </Fragment>
  )
}

const PostListing = ({ postEdges }) => {

  const getPostList = () => {
    const postList = []
    postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      })
    })
    return postList
  }

  const postList = getPostList()

  return (
    <Row gutter={gutter} className={styles.cardContainer}>
      {/* Your post list here. */
      postList.map(post => (
        <Col key={post.path} sm={24} md={12} lg={8}>
          <Link to={post.path} key={post.title}>
            <Card
              hoverable
              bordered={false}
              title={<Title>{post.title}</Title>}
              extra={<Tag color="gold" className={styles.cardExtra}>{`${post.timeToRead} Min`}</Tag>}
              actions={[
                <Text type="secondary" strong>Lire l'article</Text>
              ]}
            >
              <Meta 
                title={<MetaTitle date={post.date} tags={post.tags}/>}
                description={post.excerpt} />
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  )
}

export default PostListing
